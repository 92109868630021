<template>
  <b-card>
    <div v-if="isLoading">
      <aom-skeleton-loader />
    </div>
    <section v-else>
      <b-row>
        <filter-group-name
          v-if="isFilterGroup"
          v-model="groupNameLocal"
        />
      </b-row>
      <hr>
      <b-row>
        <b-col
          sm="12"
          class="mt-2 mb-1"
        >
          <b-form-group>
            <b-row>
              <b-col sm="2">
                <h5 class="d-inline align-middle">
                  Results must match
                </h5>
              </b-col>
              <b-col sm="2">
                <v-select
                  v-model="filterSetValue"
                  placeholder=""
                  :options="filterSet"
                  label="name"
                  :clearable="false"
                  class="d-inline"
                  :reduce="(obj) => obj.id"
                  @input="onAnyAllInput"
                />
              </b-col>
              <b-col sm="4">
                <h5 class="d-inline align-middle">
                  of the following rules
                </h5>
              </b-col>
            </b-row>
          </b-form-group>
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col sm="2">
          <h5 class="d-inline align-middle">
            Select a filter template
          </h5>
        </b-col>
        <b-col sm="5">
          <v-select
            v-model="templateRule"
            placeholder="Please select a template"
            :options="mergedRuleTemplates"
            label="name"
            :clearable="false"
          />
        </b-col>
        <b-col sm="3">
          <b-button
            variant="primary"
            class=""
            @click="addTemplateRule"
          >
            <feather-icon
              icon="DownloadIcon"
              size="14"
              class="mr-50"
            />Add
            rules
          </b-button>
        </b-col>
      </b-row>
      <section>
        <rule-builder
          :model.sync="model"
          :entity="entity"
          add-rule
          @addRule="addRule"
          @addExpression="addExpression"
          @removeExpression="removeExpression"
          @fieldUpdate="onFieldUpdate"
          @operatorUpdate="onOperatorUpdate"
        />
        <hr>
      </section>
      <b-row>
        <b-col sm="2">
          <h5 class="d-inline align-middle">
            <feather-icon
              icon="PlusIcon"
              size="20"
            />
            Add Rule
          </h5>
        </b-col>
        <b-col sm="5">
          <b-form-group label-for="Entity">
            <v-select
              v-model="entity"
              placeholder="Add New Rule"
              :options="entityDropDownOptions"
              label="name"
              :clearable="false"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <hr>
    </section>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  VBTooltip,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { getValidationState } from "@/libs/utils";
import { makeErrorToast } from "@/libs/utils";
import {
  entitysDisplay,
  advancedSeachModel,
  filters,
  dateOperators,
  componentTypes,
  ruleTemplates,
  programTypes,
  entitysPartPreferDisplay,
  partnerPrefercingRules,
  partnerPrefTemplates,
  trainingTemplates,
  userRoles,
} from "@models";
import RuleBuilder from "./partials/RuleBuilder.vue";
import { mapGetters } from "vuex";
import FilterGroupName from "./partials/FilterGroupName.vue";
import AomSkeletonLoader from "@aom-core/AomSkeletonLoader.vue";
import _isEqual from "lodash/isEqual";
import { roleAlias } from "@/@aom-core/utils/utils";
import _cloneDeep from "lodash/cloneDeep";
import { applicationsFilterFields, commonOperators,  entitys, trainingFilterFields, usersRolesFilterFieldsDisplayForTraining  } from "@/models/enums/advancedSearch";


export default {
  name: "AdvancedSearch",
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    RuleBuilder,
    FilterGroupName,
    AomSkeletonLoader,
    BButton,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    initialRules: {
      type: Object,
      default: () => {},
    },
    isFilterGroup: {
      type: Boolean,
      default: false,
    },
    groupName: {
      type: String,
      default: "",
    },
  },

  emits: ["update:addedRules"],
  data() {
    return {
      entity: undefined,
      field: undefined,
      fieldDisplay: undefined,
      operator: undefined,
      operatorDisplay: undefined,
      value: "",
      valueDisplay: undefined,
      isComponent: undefined,
      model: this.initialRules,
      isUpdating: false,
      userCountExpr: undefined,
      groupNameLocal: "",
      fetchingPreview: false,
      isLoading: false,
      filterSet: [
        { id: filters.FILTER_SET_ALL, name: "All" },
        { id: filters.FILTER_SET_ANY, name: "Any" },
      ],
      templateRule: undefined,
      filterSetValue: undefined,
      mergedAdvancedSeachModel: { ...this.getAdvancedSeachModel() },
      entityDropDownOptions: entitysDisplay,
      mergedRuleTemplates: [],
      ruleTemplatesClone : _cloneDeep(ruleTemplates)
    };
  },
  computed: {
    ...mapGetters("programs", [
      "programById",
      "defaultProgramId",
      "defaultProgram",
    ]),
    ...mapGetters("profile", ['profile', 'isProgramAdmin', 'isAdmin']),
    showAddedRules() {
      return this.model.filters.length !== 0;
    },
    isProgramTypePartnerPreferencing() {
      return this.defaultProgram?.type_id === programTypes.PARTNER_PREFERENCING;
    },
    isProgramTypeTraining() {
      return this.defaultProgram?.type_id === programTypes.TRAINING;
    },
    hasProgramAdminRole() {
      return this.isProgramAdmin(Number(this.defaultProgramId));
    },
  },
  watch: {
    defaultProgram: {
      handler(n) {
        if (n) {
          this.setEntity();
        }
      },
      deep: true,
      immediate: true,
    },
    entity(n) {
      if (n) {
        this.loadEntityOperatorSet(n);
      }
    },
    model: {
      handler(n, o) {
        if (_isEqual(n, o)) {
          return;
        }
        if (n.filters.length > 0) {
          this.$emit("update", n);
        }
      },
      deep: true,
    },
    groupNameLocal(n) {
      if (n) {
        this.$emit("update:groupName", n);
      }
    },
    groupName: {
      handler(n) {
        this.groupNameLocal = n;
      },
      immediate: true,
    },
    "model.type": {
      handler(n) {
        if (n) {
          this.$emit("update:type", n);
        }
      },
    },
    initialRules: {
      handler(n) {
        this.mergedAdvancedSeachModel = { ...this.getAdvancedSeachModel() };
        this.setEntity();
        if (n && Object.keys(this.mergedAdvancedSeachModel).length > 0) {
          this.model = {
            ...this.model,
            ...n,
            filters: [
              ...n.filters.map(f => ({
                ...f,
                aggregate: {
                  ...this.mergedAdvancedSeachModel[f.type].aggregate,
                  ...f.aggregate,
                },
                fieldDisplay: this.mergedAdvancedSeachModel[f.type].field,
                operatorDisplay: this.mergedAdvancedSeachModel[f.type].op,
                valueDisplay: this.mergedAdvancedSeachModel[f.type].values,
                component: this.mergedAdvancedSeachModel[f.type].component,
                defaultValues:
                  this.mergedAdvancedSeachModel[f.type].defaultValues,
                service: this.mergedAdvancedSeachModel[f.type].service,
              })),
            ],
          };
          this.filterSetValue = n.type || filters.FILTER_SET_ALL;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  async created() {
    await this.fetchCurrentProgram();
    let mentorApplication = this.defaultProgram?.application_set?.applications?.find(application => application.roles.find(role => role.id === userRoles.MENTOR));
    if (mentorApplication) {
      let unmatchedMentor = this.ruleTemplatesClone.find(rule => rule.name == 'Unmatched mentors with completed applications');
      if (unmatchedMentor) {
        unmatchedMentor.filters[1].expressions.push({ 
          field: applicationsFilterFields.APPLICATION_FORM,
          op: commonOperators.OP_EQUAL_TO,
          value: mentorApplication.id
        });
      }
    }
    
    this.mergedRuleTemplates = this.replaceRoleAlias(this.ruleTemplatesClone);
  },
  methods: {
    getAdvancedSeachModel() {
      let advancedSeachModelClone = _cloneDeep(advancedSeachModel);
      if (!this.isAdmin && !this.hasProgramAdminRole) {
        advancedSeachModelClone.user_roles.values['user_roles.role_id'] = advancedSeachModelClone.user_roles.values['user_roles.role_id'].filter(item => item.value !== userRoles.CHAMPION);
      }

      if(this.isProgramTypeTraining){
        advancedSeachModelClone.user_roles.values['user_roles.role_id'] = advancedSeachModelClone.user_roles.values['user_roles.role_id'].filter(item => [userRoles.TRAINEE].indexOf(item.value) !== -1);
      }else{
        advancedSeachModelClone.user_roles.values['user_roles.role_id'] = advancedSeachModelClone.user_roles.values['user_roles.role_id'].filter(item => item.value !== userRoles.TRAINEE);
      }
      
      return advancedSeachModelClone;
    },
    setEntity() {
      if (this.isProgramTypePartnerPreferencing) {
        this.entityDropDownOptions = [
          ...entitysDisplay,
          ...entitysPartPreferDisplay,
        ];
        this.mergedAdvancedSeachModel = {
          ...this.getAdvancedSeachModel(),
          ...partnerPrefercingRules,
        };
        this.mergedRuleTemplates = this.replaceRoleAlias([
          ...this.ruleTemplatesClone,
          ...partnerPrefTemplates,
        ]);
      }
      if (this.isProgramTypeTraining) {
  
        this.entityDropDownOptions = [
          ...entitysDisplay.filter(entity => [entitys.USER_ROLES, entitys.TRAININGS].indexOf(entity.id) !== -1),
        ];
        this.mergedAdvancedSeachModel[entitys.TRAININGS].values[trainingFilterFields.TRAINING_ROLE] = [{ value: 'userRoles.TRAINEE', text: 'Learner' }];
        this.mergedAdvancedSeachModel[entitys.USER_ROLES].defaultValues['user_roles.role_id'] = userRoles.TRAINEE;
        this.mergedAdvancedSeachModel[entitys.USER_ROLES].field = usersRolesFilterFieldsDisplayForTraining;
        this.mergedRuleTemplates = this.replaceRoleAlias([
          ...trainingTemplates,
        ]);
      }
    },
    replaceRoleAlias(ruleTemplates) {
      return ruleTemplates.map(ruleTemplate => {
        ruleTemplate.name = ruleTemplate.name.replace(
          /Mentor/g,
          roleAlias(userRoles.MENTOR, this.defaultProgram)
        );
        ruleTemplate.name = ruleTemplate.name.replace(
          /Mentee/g,
          roleAlias(userRoles.MENTEE, this.defaultProgram)
        );
        ruleTemplate.name = ruleTemplate.name.replace(
          /Learner/g,
          roleAlias(userRoles.MENTEE, this.defaultProgram)
        );
        return ruleTemplate;
      });
    },
    onAnyAllInput(v) {
      if (this.model.type === v) {
        return;
      }
      this.model = { ...this.model, type: v };
    },
    loadEntityOperatorSet() {
      const id = this.entity.id;
      this.model = {
        ...this.model,
        filters: [
          ...this.model.filters,
          {
            fieldDisplay: this.mergedAdvancedSeachModel[id].field,
            operatorDisplay: this.mergedAdvancedSeachModel[id].op,
            valueDisplay: this.mergedAdvancedSeachModel[id].values,
            component: this.mergedAdvancedSeachModel[id].component,
            type: this.entity.id,
            aggregate: this.mergedAdvancedSeachModel[id].aggregate,
            defaultValues: this.mergedAdvancedSeachModel[id].defaultValues,
            service: this.mergedAdvancedSeachModel[id].service,
            expressions: this.mergedAdvancedSeachModel[id]?.field.length > 0 &&
              this.mergedAdvancedSeachModel[id]?.op[
                this.mergedAdvancedSeachModel[id]?.field[0]?.id
              ] && [
                {
                  field: this.mergedAdvancedSeachModel[id]?.field[0]?.id,
                  op: this.mergedAdvancedSeachModel[id]?.op[
                    this.mergedAdvancedSeachModel[id]?.field[0]?.id
                  ][0]?.id,
                  value:
                    this.mergedAdvancedSeachModel[id]?.defaultValues[
                      [this.mergedAdvancedSeachModel[id]?.field[0]?.id]
                    ],
                },
              ],
          },
        ],
      };
      this.entity = null;
    },
    addRule() {
      this.model = {
        ...this.model,
        type: this.model.type,
        filters: [...this.model.filters],
      };
    },
    removeRule(filterIndex) {
      const filterArray = this.model.filters.filter(
        (exp, index) => index !== filterIndex
      );
      if (filterArray.length === 0) {
        this.model.filters = [];
      } else {
        this.model.filters = filterArray;
      }
    },
    removeRuleAddedRules(filterIndex) {
      const filterArray = this.model.filters.filter(
        (exp, index) => index !== filterIndex
      );
      if (filterArray.length === 0) {
        this.model.filters = [];
      } else {
        this.model.filters = filterArray;
      }
    },
    addExpression(entityId, index) {
      if (index !== -1) {
        const updateArray = (this.model.filters[index].expressions = [
          ...this.model.filters[index].expressions,
          {
            field: this.mergedAdvancedSeachModel[entityId].field[0].id,
            op: this.mergedAdvancedSeachModel[entityId].op[
              this.mergedAdvancedSeachModel[entityId].field[0].id
            ][0].id,
            value:
              this.mergedAdvancedSeachModel[entityId].defaultValues[
                this.mergedAdvancedSeachModel[entityId].field[0].id
              ] ?? "",
          },
        ]);

        this.model.filters[index].expressions = updateArray;
        this.$emit("update", this.model);
      }
    },
    removeExpression(args) {
      const { filterIndex, exprIndex } = args;
      const expressions = this.model.filters[filterIndex].expressions.filter(
        (exp, index) => index !== exprIndex
      );
      this.model.filters[filterIndex].expressions = expressions;
      if (this.model.filters[filterIndex].expressions.length === 0) {
        this.removeRule(filterIndex);
        this.entity = undefined;
      }
      this.$emit("update", this.model);
    },
    onFieldUpdate(val) {
      const { inner, outer } = val;
      if (this.model?.filters[outer]?.defaultValues) {
        const fieldKey = this.model.filters[outer].expressions[inner].field;
        const defaultValue = this.model.filters[outer].defaultValues[fieldKey];
        this.model.filters[outer].expressions[inner].value = defaultValue;
      }
    },
    onOperatorUpdate(val) {
      const { inner, outer } = val;
      if (this.model?.filters[outer]?.defaultValues) {
        const fieldKey = this.model.filters[outer].expressions[inner].field;
        const operatorkey = this.model.filters[outer].expressions[inner].op;
        const fullKey = `${fieldKey}.${operatorkey}`;
        let defaultValue = this.model.filters[outer].defaultValues[fullKey];
        if (defaultValue === undefined) {
          defaultValue = this.model.filters[outer].defaultValues[fieldKey];
        }
        this.model.filters[outer].expressions[inner].value = defaultValue;
      }
    },
    async fetchCurrentProgram() {
      try {
        this.isLoading = true;
        await this.$store.dispatch(
          "champions/FETCH_CURRENT_PROGRAM",
          this.$route.params.id
        );
      } catch (e) {
        this.$toast(makeErrorToast("Error Fetching Current Program Details."));
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
    adjustBetweenDates() {
      const newArray = this.model.filters.map(filter => {
        if (filter.expressions && Array.isArray(filter.expressions)) {
          return [
            {
              ...filter,
              expressions: filter.expressions.map(exp => {
                const isDate =
                  !!exp &&
                  this.mergedAdvancedSeachModel[filter.type].component[
                    exp.field
                  ] === componentTypes.DATE;
                const isBetweenOp =
                  String(exp?.op) === String(dateOperators.OP_BETWEEN);
                if (isDate && exp) {
                  let value = exp.value;
                  if (typeof value === "string") {
                    const dateRange = value.split(" to ");
                    if (isBetweenOp) {
                      const startDate = dateRange[0];
                      const endDate = dateRange[1] || startDate;
                      return { ...exp, value: [startDate, endDate] };
                    } else {
                      return { ...exp, value: dateRange[0] };
                    }
                  } else if (Array.isArray(value)) {
                    if (isBetweenOp) {
                      return {
                        ...exp,
                        value: [value[0], value[1] || value[0]],
                      };
                    } else {
                      return { ...exp, value: value[0] };
                    }
                  }
                }
                return exp;
              }),
            },
          ];
        }
        return filter;
      });
      return newArray.flat();
    },
    addTemplateRule() {
      this.$nextTick(() => {
        this.model = {
          ...this.model,
          filters: this.templateRule.filters,
          type: this.templateRule.type || filters.FILTER_SET_ALL,
        };
      });
    },
  },
  setup() {
    return {
      programTypes,
      getValidationState,
      ruleTemplates,
    };
  },
};
</script>
<style lang="scss" scoped>
.text-color-black {
  color: #6e6b7b !important;
}
.new-rule-wrapper {
  border: 1px dashed;
  width: fit-content;
  margin: auto;
  border-color: rgb(var(--aom-color-secondary)) !important;
}
</style>
